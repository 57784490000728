const Nl = {
    text: {
        choose: "matching",
        bond: "Geverifieerd en betaald een aanbetaling van $10000",
        img: "Portretweergave",
        intention: "Voettolerantie",
        casual: "Instructies voor casual seks",
        txt1: "Opmerking: Dit platform biedt de meest authentieke haak en lus producten voor luchtvracht. Om de privacy van gebruikers te beschermen, vereist lidmaatschap contact op te nemen met de klantenservice of aanbevolen door succesvolle personen.",
        txt2: "Consulteren van klantenservice",
        txt3: "gids",
        txt4: "Noteer het nummer van je zus en neem contact op met de klantenservice",
        ok: "OK",
        cancel: "annuleren",
        txt5: "In het voordeel van alle leden, slechts 1% van de bronnen worden weergegeven op de website en updates zijn onregelmatig. Neem voor gedetailleerde informatie contact op met de klantenservice.",
        txt6: "Alle ledeninformatie op het platform is echt, veilig en betrouwbaar. Het kan landelijk worden gebruikt, en nieuwe ontmoetingen zijn ook te vinden op dezelfde straat. Kies een vrouw die je leuk vindt en ontmoet haar persoonlijk. Doe nu mee~",
        txt7: "Ledeninformatie",
        txt8: "matching",
        txt9: "Gebruik het protocol",
        txt10: "Ik wil met haar uitgaan.",
        txt11: "Dit platform biedt de meest authentieke zoektocht naar deflatie. Om de privacy van persoonlijke informatie te beschermen, kan registratie alleen worden gedaan door contact op te nemen met support of het accepteren van echte naamaanbevelingen van personen die succesvol hebben gezocht naar deflatie ervaring.",
        txt12: "Momenteel 0 online koppelen",
        txt13: "44 activering",
        txt14: "Lidmaatschapsuitkeringen",
        txt15: "gebeurtenis",
        txt16: "Ledenvideo",
        txt17: "Lieg tegen hem.",
        txt18: "Ledeninformatie",
        txt19: "Nieuwe ID",
        txt20: "ontmoeten",
        txt21: "Stemming eindigt",
        txt22: "Resultaten gedurende de periode",
        txt23: "periode",
        txt24: "Willekeurig het nummer selecteren",
        credit_score: "Creditscore",
        mail: "Stationletter",
        txt25: "Upgrades vereisen 15 procentpunten",
        txt26: "Persoonlijke punten",
        txt27: "Punt ( 8361;)",
        txt28: "Punten opladen",
        txt29: "De locatie wijzigen",
        txt30: "online dienst",
        txt31: "korte inleiding",
        txt32: "Klik op gedetailleerde informatie",
        txt33: "Hervat de uitwisseling",
        txt34: "gegevensrecord",
        txt35: "Wachtwoord wijzigen",
        txt36: "De locatie wijzigen",
    },
    login: {
        title: "Aanmelden",
        username: "Voer uw gebruikersnaam in",
        password: "Voer uw wachtwoord in",
        forgetPaw: "Vergeet het wachtwoord",
        registerNow: "Geen account? Registratie onmiddellijk",
        doLogin: "Aanmelden",

    },
    profile: {
        jieguo: "Resultaten van de stemming",
        jiesu: "Einde",
        portrait: "Fotografia",
        video: "Video",
        jianjie: "PERSOONLIJKE PROJECTEN",
        liji: "Maak een afspraak",
        chongdan: "Laadbereik",
        fd: "Dame",
        yuyue: "Aanspraken",
        archives: "Meisjesbestand",
        age: "Leeftijd",
        height: "Hoogte",
        weight: "Gewicht",
        cup: "Bekken",
        city: "Steden",
        area: "Servicegebied",
        item: "Diensten"
    },
    register:{
        title: "Registratie",
        username: "Voer uw gebruikersnaam in (6-12 Engelse letters of cijfers)",
        password: "Voer uw aanmeldingswachtwoord in (6-12 Engelse letters of cijfers)",
        code: "Voer de uitnodigingscode in",
        agreement: "Ik ken en ga akkoord met de verdragen van de openingsovereenkomst.",
        chooseAgreement: "Controleer de overeenkomst voor het openen van een account hieronder!",
        doRegister: "Registratie",

    },
    footer:{
        home : "Home",
        game: "Aanspraken",
        video: "Video",
        mine: "Mijn",

    },
    game:{
        title: "Afspraakzaal",
        all: "Alle",

    },
    choose:{
        title: "Keuze van de",
        city: "Stedelijke Daquan",
        rule: "Het hele netwerk van het platform is de meest authentieke + zakelijke begeleider + passie in dezelfde stad. Om de persoonlijke privacy van elke gebruiker te waarborgen, kunnen klanten alleen deelnemen door contact op te nemen met de receptioniste of de echte naam van het senior lid van het platform.",
        price: "Prijsregeling",
        resource: "Welke middelen zijn er?",
        character: "Netrood, model, stewardess, mals, universiteitsstudenten, alleen jij kunt het niet bedenken, geen Ben kan het niet",
        scope: "Servicebereik?",
        location: "Gratis kanonnen in dezelfde stad, nationale luchtlandingen op elke locatie, lokale eerste en tweede steden in China, steden met drie lijnen kunnen ook worden geregeld door contact op te nemen met de receptionist.",

    },
    home:{
        recommend: "Aanbevolen taken",
        more: "Meer informatie",
        popularity: "Populariteit gerangschikt",
        hot: "Populaire aanbeveling",

    },
    lottery:{
        record: "Taakrecord",
        rule: "Spelregels",
        prompt: "Speltips",
        gameplay: "Selecteer een nummer uit de optionele en waardevorm om in te zetten",
        explain: "Winningsbeschrijving",
        wprole: "De som van de drie winnende nummers is 11 tot 18 groot; De som van de waarden is 3 tot 10 klein;",
        example: "Voorbeeld van gokken",
        option: "Weddenschema: klein loterijnummer: 123, dat wil zeggen, klein en middelgroot",
        task: "Taaklijst",
        money: "Beschikbare saldi",
        submit: "Ingezonden",
        selection: "Huidige selectie",
        perNote: "Bedrag per noot",
        inputMoney: "Voer het bedrag in",
        total: "Totaal",
        note: "Totaal",
        statistics: "Totaal",
        empty: "Leegorde",
        verify: "Bevestiging van de indiening",
        issue: "Aantal",
        num: "Aanbiedingsnummer",
        big: "Groot",
        small: "Klein",
        single: "Enkele",
        double: "Dubbel",
        unselected: "Niet geselecteerd",
        drawLottery: "Succesvolle toekenning, nummer:",
        kefu: "Neem contact op met de beheerder voor deze taak!",

    },
    mine:{
        recharge: "Opvulling",
        withdraw: "Fiscale zaken",
        myMoney: "Mijn portemonnee",
        detail: "Details",
        balance: "Saldo",
        finance: "Persoonlijke verklaringen",
        order: "Rekeningen",
        record: "Taakrecord",
        my: "Individuele centra",
        notice: "Voorlichting",
        service: "Online klantenservice",
        score: "Punten",
    },
    setting:{
        title: "Instellingen",
        basic: "Basisinformatie instellingen",
        password: "Aanmeldingswachtwoord",
        capital: "Wachtwoord voor geld",
        lang: "Taal",
        quit: "Log in",

    },
    setPayPassword: {
        title: "Stel het kapitaalwachtwoord in",
        opw: "Voer het financieringswachtwoord in",
        tpw: "Voer het financieringswachtwoord opnieuw in",

    },
    gameRecord:{
        reject: "afwijzen",
        profit: "winst",
        place: "een bestelling plaatsen",
        title: "Taakrecord",
        amountBet: "Bedrag van de inzet",
        create: "Plaats een bestelling",
        update: "Afwikkelingstijd",

    },
    video:{
        title: "Videotheater",
        play: "Uitzenden",

    },
    playVideo:{
        play: "Tweede uitzending",

    },
    setname:{
        title: "Wijzig de echte naam",
        name: "Naam",
        truename: "Voer uw echte naam in",
        hint: "Voor de beveiliging van uw account moet de echte naam consistent zijn met de naam van de gebonden bankkaart.",

    },
    setsex:{
        title: "Genderwijziging",

    },
    bindCard:{
        title: "Vul de incassokaart in",
        msg: "Voer uw incassokaartinformatie in",
        hint: "Beste gebruikers, om uw geld veilig te houden, bind uw echte naam en stel het opnamewachtwoord in.Als de naam niet overeenkomt met de naam van de account, kunt u geen geld opnemen.",
        card: "Account (normaal)",
        truecard: "Voer een account in (normaal)",
        name: "Accountnaam (pseudoniem)",
        truename: "Voer de accountnaam in (pseudoniem)",
        financial_institution: "Naam van de financiële instelling",
        truefinancial_institution: "Voer de naam van de financiële instelling in",
        branch: "Naam van de vestiging",
        truebranch: "Voer de naam van het filiaal in",
        branch_number: "Aantal filialen",
        truebranch_number: "Voer het filiaalnummer in"
    },
    setbank:{
        title: "Verzameling kaartinformatie",
        bank: "Voeg een bankkaart toe",
        hint: "Tip: bind een grote commerciële bank. Neem contact op met de online klantenservice als u deze wilt wijzigen.",

    },
    setLoginPassword:{
        title: "Wijzig het aanmeldingswachtwoord",
        old_password: "Oude wachtwoorden",
        old_password_p: "Voer het oude wachtwoord in",
        o_new_password: "Nieuw wachtwoord",
        o_new_password_p: "Voer een nieuw wachtwoord in",
        t_new_password_p: "Voer het nieuwe wachtwoord opnieuw in",

    },
    infomation:{
        title: "Basisinformatie",
        head: "Avatar",
        select: "Kies een avatar",
        name: "Echte naam",
        sex: "Geslacht",
        man: "Man",
        girl: "Vrouwen",
        unknown: "Onbekend",
        binding: "Bindinformatie",

    },
    withdraw: {
        title: "Contracterende centra",
        record: "Contractierecord",
        money: "Waardebedrag",
        all: "Alle",
        min: "Enkele limiet: minimum",
        max: "",
        num: "Fiscaal: een daggeld",
        hint: "Aankomsttijd: meestal is de aankomsttijd ongeveer 5 minuten en de snelste 2 minuten.",
        quota: "Omschrijving",
        balance: "Saldo",
        withdraw: "Onmiddellijk contant",

    },
    withdrawRecord:{
        desc: "Opmerking",
        create: "Datum van indiening",
        update: "Controletijd",

    },
    personalreport:{
        title: "Persoonlijke verklaringen",
        profit: "Winstgevend bedrag",
        formula: "Winstberekening formule: winnende bedrag-taakbedrag",
        task: "Bedrag van de opdracht",
        recharge: "Opladen bedrag",
        withdrawal: "Waardebedrag",
        win_money: "Winst bedrag",

    },
    service: {
        title: "Online klantenservice",
        relation: "Contact",
        hint: "7 * 24 uur per dag om u van dienst te zijn",

    },
    msg:{
        "次": "Tweede keer",
        "用户不存在！": "De gebruiker bestaat niet!",
        "密码错误！": "Wachtwoord fout!",
        "登录成功！": "Log succesvol in!",
        "邀请码不存在！": "De uitnodigingscode bestaat niet!",
        "用户名位数错误！": "Het aantal gebruikersnamen is verkeerd!",
        "密码位数错误！": "Het aantal wachtwoordbits is verkeerd!",
        "用户名不能存在中文！": "De gebruikersnaam kan niet in het Chinees bestaan!",
        "邀请码不能为空！": "De uitnodigingscode kan niet leeg zijn!",
        "注册成功！": "Succesvol registreren!",
        "注册失败！": "Registratie mislukt!",
        "用户已存在": "Gebruiker bestaat al",
        "刷新成功！": "Vernieuwing is succesvol!",
        "请联系顾问或接待员": "Neem contact op met een consultant of receptioniste",
        "金额错误！": "Het bedrag is verkeerd!",
        "请选号！": "Kies het nummer!",
        "请填写金额!": "Vul het bedrag in!",
        "余额不足，请联系客服充值！": "Onvoldoende saldo, neem contact op met de klantenservice!",
        "没有更多了": "Niet meer",
        "没有数据": "Geen gegevens",
        "余额不足！": "Onvoldoende saldo!",
        "扣款失败！": "De aftrek is mislukt!",
        "下注异常！": "De inzet is abnormaal!",
        "投注成功！": "De inzet is succesvol!",
        "参数异常！": "De parameters zijn abnormaal!",
        "获取中": "In de aankomst...",
        "请完成任务单后进入": "Voltooi de takenlijst",
        "请联系客服充值": "Neem contact op met de klantenservice",
        "请设置收款卡!": "Stel de verzamelkaart in!",
        "功能已禁用!": "De functie is uitgeschakeld!",
        "账号下线": "Account offline",
        "登录/注册": "Aanmelden/registreren",
        "登录可享受更多服务！": "Log in voor meer service!",
        "未设置": "Niet ingesteld",
        "已设置": "Gevestigd",
        "提交": "Ingezonden",
        "确定": "Bepaling",
        "请填写完整": "Vul alstublieft in",
        "两次密码不一致！": "De twee wachtwoorden zijn niet consistent!",
        "设置提现密码成功！": "Stel het contante wachtwoord in om te slagen!",
        "提现密码已设置，需要修改请联系客服": "Het contante wachtwoord is ingesteld. Neem contact op met de klantenservice als u het moet wijzigen.",
        "已绑定": "Gebonden",
        "无": "Geen",
        "更换头像成功！": "T Vervang de avatar!",
        "更新头像失败！": "Het bijwerken van de avatar is mislukt!",
        "请勿重复设置！": "Herhaal de instellingen niet!",
        "设置姓名成功！": "Stel de naam in om te slagen!",
        "设置姓名失败！": "De naam is niet ingesteld!",
        "设置性别成功！": "Stel gendersucces in!",
        "设置性别失败！": "Het instellen van gender mislukt!",
        "请设置姓名后再绑定银行卡！": "Stel uw naam in en bind de bankkaart!",
        "请设置提现密码后再绑定银行卡！": "Stel het contante wachtwoord in en bind de bankkaart!",
        "确认绑卡": "Bevestig de kaart",
        "请输入银行卡号！": "Voer de bank in!",
        "请输入银行名称！": "Voer de banknaam in!",
        "设置银行卡成功！": "Stel een bankkaart in om te slagen!",
        "设置银行卡失败！": "Het instellen van een bankkaart is mislukt!",
        "旧密码错误": "Oude wachtwoordfout",
        "修改密码成功！": "Het wijzigen van het wachtwoord is succesvol!",
        "修改密码失败！": "Het wijzigen van het wachtwoord is mislukt!",
        "请填写正确的金额": "Vul alstublieft het juiste bedrag in",
        "提现金额不能少于：": "Het bedrag van de contante betaling mag niet minder bedragen dan:",
        "提现金额不能大于：": "Het bedrag van de contante betaling mag niet groter zijn dan:",
        "当日提现次数已用完": "De rekening is op de dag opgebruikt",
        "提现成功！": "Succes!",
        "提现失败！": "Falen!",
        "金额": "Bedrag",
        "待审核": "Te beoordelen",
        "审核成功": "Succes van de audit",
        "审核退回": "Teruggave van de controle",
        "请充值后观看视频！": "Bekijk de video na het opladen!",
        "释放即可刷新": "De release kan worden vernieuwd...",
        "加载中": "In de laad...",
        "下拉即可刷新": "De volgende pull kan worden vernieuwd...",
    },
    setLang:{
        title: "Talen instellen"
    },
    lang:{
        zh: "Chinees",
        jp: "Japan",
        ara: "Taal",
        en: "NEDERLANDS",
        spa: "Spaans",
        it: "Italiaans",
        de: "Duits",
        pt: "Portugees",
        fra: "Frans",
        nl: "Nederlands",
        id: "Indonesisch",
        hi: "Hindi",
        kor: "Koreaans",
        tr: "Turks",
        swe: "Zweeds",
        ga: "Iers",
        ru: "Russisch",
        vie: "Vietnamees",
        th: "Thai",
        est: "Estlands",
        be: "Wit-Russisch",
        bul: "Bulgaars",
        pl: "Pools",
        ms: "Maleis",

    }
}
export default Nl