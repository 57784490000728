const Jp = {
    text: {
        choose: "マッチング",
        bond: "認証済み、1万ドルのデポジットを支払いました",
        img: "画像表示",
        intention: "経験満足度",
        casual: "カジュアルセックスの指示",
        txt1: "注：このプラットフォームは、最も本物の空輸フックアップを提供しています。ユーザープライバシーを保護するため、メンバーシップはカスタマーサービスに連絡するか、成功した人の推薦が必要です。",
        txt2: "カスタマーサービスに相談",
        txt3: "ご案内",
        txt4: "お姉さんの番号をメモして、カスタマーサービスに連絡してください",
        ok: "OK",
        cancel: "キャンセル",
        txt5: "会員の皆様のために、サイトにはリソースの1%のみを表示しており、更新も不定期です。詳細なリソースについては、カスタマーサービスまでお問い合わせください。",
        txt6: "プラットフォーム上のすべての会員情報は本物で、安全で信頼できます。全国で利用可能で、同じ街で新しい出会いを見つけることもできます。お気に入りの女性を選び、実際に会ってみましょう。今すぐ参加しましょう～",
        txt7: "会員情報",
        txt8: "マッチング",
        txt9: "利用規約",
        txt10: "彼女とデートしたい",
        txt11: "当プラットフォームは、最もリアルなセフレ探しを提供しています。個人情報のプライバシー保護のため、登録はサポートに連絡するか、すでに成功したセフレ探しの経験がある方からの実名推薦を受けた方のみが登録できます。",
        txt12: "現在0オンラインペアリング",
        txt13: "44アクティベートしました",
        txt14: "会員権利",
        txt15: "イベント",
        txt16: "会員ビデオ",
        txt17: "彼に乗って騙す",
        txt18: "会員情報",
        txt19: "新ID",
        txt20: "出会い",
        txt21: "投票終了",
        txt22: "期間結果",
        txt23: "期間",
        txt24: "抽選番号",
        credit_score: "信用スコア",
        mail: "ステーションレター",
        txt25: "アップグレードには15ポイント必要",
        txt26: "個人ポイント",
        txt27: "ポイント (₩)",
        txt28: "ポイントのチャージ",
        txt29: "ポイントの交換",
        txt30: "オンラインサービス",
        txt31: "プロフィール",
        txt32: "ポイント詳細",
        txt33: "交換履歴",
        txt34: "データ記録",
        txt35: "交換パスワード",
        txt36: "ポイントの交換",
    },
    login: {
        title: "ログイン",
        username: "ユーザー名を入力してください",
        password: "パスワードを入力してください",
        forgetPaw: "パスワードを忘れました",
        registerNow: "口座がない？今すぐ登録",
        doLogin: "ログイン",

    },
    profile: {
        portrait: "写真",
        video: "ライブ映像",
        jianjie:"個人プロフィール",
        liji:"今すぐ予約",
        chongdan:"充電範囲",
        fd:'貴殿',
        yuyue:"予約する",

        archives: "妹ファイル",
        age: "年齢",
        height: "身長",
        weight: "体重",
        cup: "バスト",
        city: "都市",
        area: "住所",
        item: "サービス都市"
    },
    register:{
        title: "登録",
        username: "ユーザー名を入力してください（6~12文字の英字または数字）。",
        password: "ログインパスワードを入力してください（英字または数字6~12文字）。",
        code: "招待コードを入力してください",
        agreement: "私は口座開設協定の条約を知っていて同意しています",
        chooseAgreement: "下の口座開設契約書にチェックを入れてください！",
        doRegister: "登録",

    },
    footer:{
        home : "トップページ",
        game: "認証",
        video: "ビデオ",
        mine: "私の",

    },
    game:{
        title: "認証ホール",
        all: "すべて",

    },
    choose:{
        title: "側室を選ぶ",
        city: "とまちだいすけ",
        rule: "このプラットフォームは、すべてのユーザーのプライバシーを保証するために、お客様は、コンシェルジュまたはプラットフォームのベテラン会員の実名推薦を連絡することによってのみ加入することができます。",
        price: "価格フロー",
        resource: "どのような資源がありますか。",
        character: "ユーチューバー、モデル、キャビンアテンダント、若いモデル、大学生、あなただけが思いもよらない、本平がいないわけにはいかない",
        scope: "サービス範囲。",
        location: "同都市は無料で約砲、任意の場所から全国への天下りが可能で、国内の1、2線都市は現地で運行しており、3線都市もコンシェルジュと連絡することで予約手配が可能となっている。",

    },
    home:{
        recommend: "推奨タスク",
        more: "詳細はこちら",
        popularity: "人気ランキング",
        hot: "人気のおすすめ",

    },
    lottery:{
        jieguo: '投票結果',
        jiesu: 'ピリオド',
        record: "タスクレコード",
        rule: "法規をあそぶ",
        prompt: "遊び方のヒント",
        gameplay: "オプションと値の形態から番号を選択してベットする",
        explain: "当選の説明",
        wprole: "3つの開賞番号の合計値11~18は大きい;合計値3~10は小さい;",
        example: "賭けの例",
        option: "賭注方案：小開賞番号：123,即中小",
        task: "タスクシート",
        money: "利用可能ポイント",
        submit: "だす",
        selection: "現在の選択",
        perNote: "注文ごとのポイント",
        inputMoney: "金額を入力してください",
        total: "つまり",
        note: "タック",
        statistics: "マーク",
        empty: "クリア・オーダー",
        verify: "提出の確認",
        issue: "シリアル番号",
        num: "抽選結果",
        big: "ロヶット",
        small: "飛行機",
        single: "クルーズ船",
        double: "馬車",
        unselected: "選択されていません",
        drawLottery: "当選しました。期間番号:",
        kefu: "このタスクは管理者に連絡してください。",

    },
    mine:{
        recharge: "チャージ",
        withdraw: "現金引き出し",
        myMoney: "私の財布",
        detail: "詳細",
        balance: "残高",
        finance: "個人レポート",
        order: "口座明細",
        record: "タスクレコード",
        my: "パーソナルセンター",
        notice: "情報の掲示",
        service: "オンラインカスタマーサービス",
        score: "積分",
    },
    setting:{
        title: "設定",
        basic: "プロフィール設定",
        password: "ログインパスワード",
        capital: "資金コード",
        lang: "言語",
        quit: "ログアウト",

    },
    setPayPassword: {
        title: "資金調達パスワードの設定",
        opw: "資金調達パスワードを入力してください",
        tpw: "資金パスワードをもう一度入力してください",

    },
    gameRecord:{
        reject: "却下",
        profit: "に利益を与える",
        place: "注文する",
        title: "タスクレコード",
        amountBet: "賭け金",
        create: "注文時間",
        update: "決済時間",

    },
    video:{
        title: "ビデオシアター",
        play: "再生",

    },
    playVideo:{
        play: "回再生",

    },
    setname:{
        title: "実名の変更",
        name: "名前",
        truename: "実名を入力してください",
        hint: "口座のセキュリティのためには、実名と銀行カードの名前が一致している必要があります",

    },
    setsex:{
        title: "性別変更",

    },
    bindCard:{
        title: "入金カードへの記入",
        msg: "入金カード情報を入力してください",
        hint: "尊敬するユーザー、あなたの資金の安全を保障するために、あなたの実名と設定引き出しパスワードをバインドしてください、もし名前と口座開設名が一致しない場合、引き出しできません",
        card: "口座番号",
        truecard: "インプット口座番号",
        name: "口座名義",
        truename: "インプット口座名義",
        financial_institution:"銀行名称",
        truefinancial_institution:"インプット銀行名",
        branch:"支店名称",
        truebranch:"インプット支店名",
        branch_number:"支店番号",
        truebranch_number:"インプット支店番号",

    },
    setbank:{
        title: "入金カード情報",
        bank: "銀行カードの追加",
        hint: "ヒント:大規模商業銀行をバインドして、変更する場合は、オンラインカスタマーサービスに連絡してください",

    },
    setLoginPassword:{
        title: "ログインパスワードの変更",
        old_password: "古いパスワード",
        old_password_p: "古いパスワードを入力してください",
        o_new_password: "新しいパスワード",
        o_new_password_p: "新しいパスワードを入力してください",
        t_new_password_p: "新しいパスワードを再入力してください",

    },
    infomation:{
        title: "基本情報",
        head: "アバター",
        select: "アバターを選択",
        name: "実名",
        sex: "性別",
        man: "男",
        girl: "女",
        unknown: "不明",
        binding: "バインド情報",

    },
    withdraw: {
        title: "現金引き出しセンター",
        record: "現金引出記録",
        money: "わりだし高",
        all: "すべて",
        min: "個別限度:最小",
        max: "最大値",
        num: "キャッシュアウト:1日キャッシュアウト",
        hint: "入金時間：通常の入金時間は5分程度で、最短2分以内に入金される",
        quota: "限度額摘要",
        balance: "残高",
        withdraw: "すぐに現金を引き出す",

    },
    withdrawRecord:{
        desc: "説明",
        create: "送信時刻",
        update: "レビュー時間",

    },
    personalreport:{
        title: "個人レポート",
        profit: "利益額",
        formula: "利益計算式：当選金額-タスク金額",
        task: "タスク金額",
        recharge: "チャージ金額",
        withdrawal: "わりだし高",
        win_money: "当せん金",

    },
    service: {
        title: "オンラインカスタマーサービス",
        relation: "つながり",
        hint: "24時間365日対応",

    },
    msg:{
        "次": "次",
        "用户不存在！": "ユーザーが存在しません！",
        "密码错误！": "パスワードが間違っています！",
        "登录成功！": "ログインに成功しました！",
        "邀请码不存在！": "招待コードが存在しません！",
        "用户名位数错误！": "ユーザー名の桁数が間違っています！",
        "密码位数错误！": "パスワードの桁数が間違っています！",
        "用户名不能存在中文！": "ユーザー名は数字またはアルファベットでなければならない。",
        "邀请码不能为空！": "招待コードを空にすることはできません！",
        "注册成功！": "登録に成功しました！",
        "注册失败！": "登録に失敗しました！",
        "用户已存在": "ユーザーはすでに存在します",
        "刷新成功！": "更新に成功しました！",
        "请联系顾问或接待员": "コンサルタントまたはコンシェルジュに連絡してください",
        "金额错误！": "金額が間違っています！",
        "请选号！": "番号をお選びください！",
        "请填写金额!": "金額を記入してください！",
        "余额不足，请联系客服充值！": "残高不足です。カスタマーサービスに連絡してチャージしてください！",
        "没有更多了": "それ以上はない",
        "没有数据": "データなし",
        "余额不足！": "残高が不足しています！",
        "扣款失败！": "引き落とし失敗！",
        "下注异常！": "賭けが異常だ！",
        "投注成功！": "賭けは成功した！",
        "参数异常！": "パラメータが異常です！",
        "获取中": "取得中…",
        "请完成任务单后进入": "タスク・シートの完了後",
        "请联系客服充值": "カスタマーサービスに連絡してチャージしてください",
        "请设置收款卡!": "入金カードをセットしてください！",
        "功能已禁用!": "機能が無効になりました！",
        "账号下线": "アカウントダウン",
        "登录/注册": "ログイン/登録",
        "登录可享受更多服务！": "ログインすると、さらに多くのサービスを利用できます！",
        "未设置": "設定されていません",
        "已设置": "設定済み",
        "提交": "提出",
        "确定": "確定",
        "请填写完整": "完全に記入してください",
        "两次密码不一致！": "2回のパスワードが一致していません！",
        "设置提现密码成功！": "キャッシュアウトパスワードの設定に成功しました！",
        "提现密码已设置，需要修改请联系客服": "キャッシングパスワードが設定されています。変更が必要です。サポートにお問い合わせください",
        "已绑定": "バインド済み",
        "无": "なし",
        "更换头像成功！": "Tアバターの交換に成功！",
        "更新头像失败！": "アバターの更新に失敗しました！",
        "请勿重复设置！": "設定を繰り返さないでください！",
        "设置姓名成功！": "名前の設定に成功しました！",
        "设置姓名失败！": "名前の設定に失敗しました！",
        "设置性别成功！": "性別を設定して成功しました！",
        "设置性别失败！": "性別の設定に失敗しました！",
        "请设置姓名后再绑定银行卡！": "名前を設定してからカードをひも付けてください！",
        "请设置提现密码后再绑定银行卡！": "キャッシュカードをバインドする前に、キャッシングパスワードを設定してください！",
        "确认绑卡": "バインディングカードの確認",
        "请输入银行卡号！": "銀行を入力してください！",
        "请输入银行名称！": "銀行名を入力してください！",
        "设置银行卡成功！": "銀行カードの設定に成功しました！",
        "设置银行卡失败！": "銀行カードの設定に失敗しました！",
        "旧密码错误": "旧パスワードエラー",
        "修改密码成功！": "パスワードの変更に成功しました！",
        "修改密码失败！": "パスワードの変更に失敗しました！",
        "请填写正确的金额": "正しい金額を記入してください",
        "提现金额不能少于：": "引き出し額は次の値より小さくすることはできません：",
        "提现金额不能大于：": "引き出し額は次の値より大きくすることはできません：",
        "当日提现次数已用完": "現金はその日のうちに使い切った",
        "提现成功！": "現金引き出しに成功しました！",
        "提现失败！": "現金引き出しに失敗しました！",
        "金额": "金額",
        "待审核": "レビュー待ち",
        "审核成功": "監査に成功しました",
        "审核退回": "監査返却",
        "请充值后观看视频！": "チャージして動画をご覧ください！",
        "释放即可刷新": "解放すれば更新...",
        "加载中": "ロード中...",
        "下拉即可刷新": "ドロップダウンで更新...",
    },
    setLang:{
        title: "言語の設定"
    },
    lang:{
        zh: "中国語",
        jp: "日本",
        ara: "ご",
        en: "英語",
        spa: "スペイン語",
        it: "イタリア語",
        de: "ドイツ語",
        pt: "ポルトガル語",
        fra: "フランス語",
        nl: "オランダ語",
        id: "インドネシア語",
        hi: "ヒンディー語",
        kor: "韓国語",
        tr: "トルコ語",
        swe: "スウェーデン語",
        ga: "アイルランド語",
        ru: "ロシア語",
        vie: "ベトナム語",
        th: "タイ語",
        est: "エストニア語",
        be: "ベラルーシ語",
        bul: "ブルガリア語",
        pl: "ポーランド語",
        ms: "マレー語",
    }
}
export default Jp