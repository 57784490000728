const Ara = {
    text: {
        choose: "مطابقة",
        bond: "وقد تم التصديق على دفع إيداع 10000 دولار",
        img: "تمثيل تصويري",
        intention: "كفاية",
        casual: "تعليمات الجنس الترفيهية",
        txt1: "ملاحظة : هذا المنبر يوفر أكثر حقيقية الهواء هوك خواتم . من أجل حماية خصوصية المستخدم ، عضوية يتطلب الاتصال بخدمة العملاء أو شخص ناجح يوصي .",
        txt2: "استشارات خدمة العملاء",
        txt3: "مرشد",
        txt4: "اكتب رقم أختك ، اتصل بخدمة العملاء",
        ok: "حَسَناً",
        cancel: "ألغى",
        txt5: "بالنسبة للأعضاء ، فقط 1 ٪ من الموارد التي يتم عرضها على الموقع و التحديثات غير منتظمة . لمزيد من المعلومات حول الموارد ، يرجى الاتصال بخدمة العملاء .",
        txt6: "جميع معلومات الأعضاء على منصة حقيقية وآمنة وموثوق بها . يمكن استخدامها في جميع أنحاء البلاد ، ويمكن أيضا العثور على لقاءات جديدة في نفس الشارع . اختيار المرأة التي تحب ، في الواقع الوفاء بها . الانضمام إلينا الآن",
        txt7: "معلومات الأعضاء",
        txt8: "مطابقة",
        txt9: "استخدام البروتوكول",
        txt10: "أريد أن أخرج معها .",
        txt11: "هذا المنبر يوفر أكثر واقعية تبحث عن الانكماش . من أجل حماية خصوصية المعلومات الشخصية الخاصة بك ، يمكنك فقط الاتصال دعم التسجيل ، أو قبول الشخص الذي نجح في العثور على الانكماش تجربة الاسم الحقيقي الموصى به .",
        txt12: "حاليا 0 على الانترنت الاقتران",
        txt13: "التنشيط",
        txt14: "حقوق العضوية",
        txt15: "الأحداث",
        txt16: "فيديو الأعضاء",
        txt17: "خداع له",
        txt18: "معلومات الأعضاء",
        txt19: "معرف جديد",
        txt20: "لقاء",
        txt21: "نهاية التصويت",
        txt22: "نتائج الفترة",
        txt23: "فترة",
        txt24: "اختيار عدد",
        credit_score: "درجة الائتمان",
        mail: "رسالة من محطة",
        txt25: "الترقية تتطلب 15 في المائة .",
        txt26: "فرد لا يتجزأ",
        txt27: "نقطة ( \\ 8361 ؛ )",
        txt28: "جزءا لا يتجزأ من شحن",
        txt29: "استبدال نقطة",
        txt30: "خدمات الانترنت",
        txt31: "مقدمة",
        txt32: "نقطة معلومات مفصلة",
        txt33: "تبادل السير الذاتية",
        txt34: "تسجيل البيانات",
        txt35: "تغيير كلمة السر",
        txt36: "استبدال نقطة",

    },
    login: {
        title: "تسجيل الدخول",
        username: "الرجاء إدخال اسم المستخدم",
        password: "الرجاء إدخال كلمة السر",
        forgetPaw: "نسيت كلمة السر",
        registerNow: "لا حساب؟ سجل الآن",
        doLogin: "تسجيل الدخول",

    },
    profile: {
        portrait: "صورة",
        video: "فيديو",
        jianjie: "الملامح الشخصية",
        liji: "حجز على الفور",
        chongdan: "نطاق الشحن",
        fd: "امرأة نبيلة",
        yuyue: "تحديد المواعيد",
        archives: "ملف الفتاة",
        age: "العمر",
        height: "الطول",
        weight: "الوزن",
        cup: "الكؤوس",
        city: "المناطق الحضرية",
        area: "منطقة الخدمة",
        item: "نوع الخدمة"
    },
    register:{
        title: "التسجيل",
        username: "الرجاء إدخال اسم المستخدم ( 6-12 حرفًا أو رقمًا باللغة الإنجليزية )",
        password: "الرجاء إدخال كلمة مرور تسجيل الدخول ( 6-12 حرفًا أو رقمًا باللغة الإنجليزية )",
        code: "الرجاء إدخال رمز الدعوة",
        agreement: "أنا أعرف بالفعل وأوافق على معاهدات اتفاقية فتح الحساب",
        chooseAgreement: "يرجى التحقق من اتفاقية فتح الحساب أدناه !",
        doRegister: "التسجيل",

    },
    footer:{
        home : "الصفحة الرئيسية",
        game: "تحديد المواعيد",
        video: "فيديو",
        mine: "بلدي",

    },
    game:{
        title: "قاعة المواعيد",
        all: "المجموع",

    },
    choose:{
        title: "اختيار الأميرة",
        city: "موسوعة المدينة",
        rule: "الشبكة الكاملة لهذه المنصة هي الأكثر واقعية + مرافقة أعمال + شغف في نفس المدينة، ولضمان الخصوصية الشخصية لكل مستخدم، لا يمكن للعملاء الانضمام إلا عن طريق الاتصال بموظف الاستقبال أو التوصية بالاسم الحقيقي لكبار أعضاء المنصة.",
        price: "تدفق الأسعار",
        resource: "ما هي الموارد المتاحة؟",
        character: "شبكة حمراء، نماذج، مضيفات، نماذج رقيقة، طلاب جامعيون، فقط لا يمكنك التفكير في الأمر، لا يمكن تحقيق أي شيء",
        scope: "نطاق الخدمة؟",
        location: "مواعيد مجانية في نفس المدينة، محمولة جواً في أي مكان في جميع أنحاء البلاد، ومدن الدرجة الأولى والثانية في الصين متوفرة محليًا، ويمكن لمدن الدرجة الثالثة أيضًا الاتصال بموظفي الاستقبال لتحديد المواعيد.",

    },
    home:{
        recommend: "المهام الموصى بها",
        more: "عرض المزيد",
        popularity: "تصنيف الشعبية",
        hot: "التوصيات الشعبية",

    },
    lottery:{
        jieguo: "نتائج التصويت",
        jiesu: "ختام الجلسة",
        record: "سجل المهمة",
        rule: "قواعد اللعب",
        prompt: "نصائح اللعب",
        gameplay: "حدد الرقم من نموذج قيمة المجموع الاختياري للمراهنة",
        explain: "الوصف الفائز",
        wprole: "القيمة الإجمالية لأرقام اليانصيب الثلاثة هي 11 إلى 18؛ القيمة الإجمالية من 3 إلى 10 صغيرة؛",
        example: "مثال الرهان",
        option: "خطة الرهان : رقم اليانصيب الصغير : 123، أي الصغيرة والمتوسطة",
        task: "قائمة المهام",
        money: "الرصيد المتاح",
        submit: "التقديم",
        selection: "الاختيار الحالي",
        perNote: "المبلغ لكل ملاحظة",
        inputMoney: "الرجاء إدخال المبلغ",
        total: "المجموع",
        note: "المجموع",
        statistics: "المجموع",
        empty: "إفراغ الطلب",
        verify: "تأكيد التقديم",
        issue: "العدد",
        num: "رقم السحب",
        big: "كبير",
        small: "صغير",
        single: "الأوامر",
        double: "مزدوج",
        unselected: "لا يوجد إختيار",
        drawLottery: "الجائزة الناجحة، العدد :",
        kefu: "يرجى الاتصال بالمسؤول لهذه المهمة !",

    },
    mine:{
        recharge: "التعبئة",
        withdraw: "السحب النقدي",
        myMoney: "محفظتي",
        detail: "التفاصيل",
        balance: "الرصيد",
        finance: "البيانات الشخصية",
        order: "تفاصيل الحساب",
        record: "سجل المهمة",
        my: "مركز الأفراد",
        notice: "نشرة إعلامية",
        service: "خدمة العملاء عبر الإنترنت",
        score: "نقاط",
    },
    setting:{
        title: "إعداد",
        basic: "إعدادات المعلومات الأساسية",
        password: "كلمة مرور تسجيل الدخول",
        capital: "كلمة مرور الأموال",
        lang: "اللغات",
        quit: "الخروج من تسجيل الدخول",

    },
    setPayPassword: {
        title: "تعيين كلمة مرور الأموال",
        opw: "الرجاء إدخال كلمة مرور الأموال",
        tpw: "الرجاء إدخال كلمة مرور الأموال مرة أخرى",

    },
    gameRecord:{
        reject: "رفض",
        profit: "ربح",
        place: "وضع النظام",
        title: "سجل المهمة",
        amountBet: "مبلغ الرهان",
        create: "وقت الطلب",
        update: "وقت التسوية",

    },
    video:{
        title: "مسرح فيديو",
        play: "اللعب",

    },
    playVideo:{
        play: "البث الإذاعي",

    },
    setname:{
        title: "تعديل الاسم الحقيقي",
        name: "الاسم",
        truename: "الرجاء إدخال الاسم الحقيقي",
        hint: "من أجل أمان حسابك، يجب أن يكون الاسم الحقيقي متسقًا مع اسم البطاقة المصرفية المنضمة",

    },
    setsex:{
        title: "تعديل نوع الجنس",

    },
    bindCard:{
        title: "املأ بطاقة الاستلام",
        msg: "الرجاء إدخال معلومات بطاقة الإيصال الخاصة بك",
        hint: "عزيزي المستخدم، من أجل حماية أموالك، يرجى ربط اسمك الحقيقي وتعيين كلمة مرور السحب، وإذا كان الاسم لا يتوافق مع اسم فتح الحساب، فلن تتمكن من السحب",
        card: "رقم الحساب ( عادي )",
        truecard: "أدخل رقم الحساب ( عادي )",
        name: "اسم الحساب ( الاسم الحقيقي )",
        truename: "أدخل اسم الحساب ( الاسم الحقيقي )",
        financial_institution: "اسم المؤسسة المالية",
        truefinancial_institution: "أدخل اسم المؤسسة المالية",
        branch: "اسم الفرع",
        truebranch: "أدخل اسم الفرع",
        branch_number: "رقم الفرع",
        truebranch_number: "أدخل رقم الفرع"
    },
    setbank:{
        title: "معلومات بطاقة الدفع",
        bank: "إضافة بطاقة مصرفية",
        hint: "نصيحة : يرجى ربط البنوك التجارية الكبيرة، وإذا كنت بحاجة إلى تعديل، يرجى الاتصال بخدمة العملاء عبر الإنترنت",

    },
    setLoginPassword:{
        title: "تغيير كلمة مرور تسجيل الدخول",
        old_password: "كلمة المرور القديمة",
        old_password_p: "الرجاء إدخال كلمة المرور القديمة",
        o_new_password: "كلمة السر الجديدة",
        o_new_password_p: "الرجاء إدخال كلمة مرور جديدة",
        t_new_password_p: "الرجاء إدخال كلمة مرور جديدة مرة أخرى",

    },
    infomation:{
        title: "معلومات أساسية",
        head: "الصورة الرمزية",
        select: "اختر الصورة الرمزية",
        name: "الاسم الحقيقي",
        sex: "نوع الجنس",
        man: "ذكور",
        girl: "إناث",
        unknown: "غير معروف",
        binding: "ربط المعلومات",

    },
    withdraw: {
        title: "مركز السحب",
        record: "سجل السحب",
        money: "المبلغ المسحوب",
        all: "المجموع",
        min: "الحد اﻷدنى : الحد اﻷدنى",
        max: "",
        num: "السحب : سحب يوم واحد",
        hint: "وقت الوصول : عادة ما يكون وقت الوصول حوالي 5 دقائق، ويتم الوصول في أسرع دقيقتين",
        quota: "بيان الحدود",
        balance: "الرصيد",
        withdraw: "سحب الآن",

    },
    withdrawRecord:{
        desc: "الحواشي",
        create: "وقت التقديم",
        update: "وقت المراجعة",

    },
    personalreport:{
        title: "البيانات الشخصية",
        profit: "مبلغ الربح",
        formula: "صيغة حساب الربح : المبلغ الفائز - مبلغ المهمة",
        task: "مبلغ المهمة",
        recharge: "مبلغ إعادة التغذية",
        withdrawal: "المبلغ المسحوب",
        win_money: "المبلغ الفائز",

    },
    service: {
        title: "خدمة العملاء عبر الإنترنت",
        relation: "الاتصالات",
        hint: "في خدمتك 7 * 24 ساعة في اليوم",

    },
    msg:{
        "次": "العدد",
        "用户不存在！": "المستخدم غير موجود !",
        "密码错误！": "كلمة المرور خاطئة !",
        "登录成功！": "تسجيل الدخول بنجاح !",
        "邀请码不存在！": "رمز الدعوة غير موجود !",
        "用户名位数错误！": "رقم اسم المستخدم غير صحيح !",
        "密码位数错误！": "رقم كلمة المرور غير صحيح !",
        "用户名不能存在中文！": "لا يمكن أن يكون اسم المستخدم موجودًا باللغة الصينية !",
        "邀请码不能为空！": "لا يمكن أن يكون رمز الدعوة فارغًا !",
        "注册成功！": "تسجيل ناجح !",
        "注册失败！": "فشل التسجيل !",
        "用户已存在": "المستخدم موجود بالفعل",
        "刷新成功！": "تحديث بنجاح !",
        "请联系顾问或接待员": "يرجى الاتصال بالمستشار أو موظف الاستقبال",
        "金额错误！": "مبلغ خاطئ !",
        "请选号！": "يرجى اختيار الرقم !",
        "请填写金额!": "يرجى ملء المبلغ !",
        "余额不足，请联系客服充值！": "الرصيد غير كافٍ، يرجى الاتصال بخدمة العملاء لإعادة الشحن !",
        "没有更多了": "لا أكثر",
        "没有数据": "لا توجد بيانات",
        "余额不足！": "الرصيد غير كافٍ !",
        "扣款失败！": "فشل الخصم !",
        "下注异常！": "الرهان غير طبيعي !",
        "投注成功！": "الرهان ناجح !",
        "参数异常！": "استثناء المعلمة !",
        "获取中": "الحصول على...",
        "请完成任务单后进入": "يرجى إكمال قائمة المهام",
        "请联系客服充值": "يرجى الاتصال بخدمة العملاء لإعادة الشحن",
        "请设置收款卡!": "يرجى إعداد بطاقة استلام !",
        "功能已禁用!": "تم تعطيل الوظيفة !",
        "账号下线": "حساب غير متصل",
        "登录/注册": "تسجيل الدخول / التسجيل",
        "登录可享受更多服务！": "تسجيل الدخول للاستمتاع بمزيد من الخدمات !",
        "未设置": "غير معدّة",
        "已设置": "تم إعداده",
        "提交": "التقديم",
        "确定": "تحديد",
        "请填写完整": "يرجى ملء كاملة",
        "两次密码不一致！": "كلمة المرور غير متناسقة مرتين !",
        "设置提现密码成功！": "تعيين كلمة مرور السحب بنجاح !",
        "提现密码已设置，需要修改请联系客服": "تم تعيين كلمة مرور السحب وتحتاج إلى تعديل، يرجى الاتصال بخدمة العملاء",
        "已绑定": "ملتزم",
        "无": "لا يوجد",
        "更换头像成功！": "تي تغيير الصورة الرمزية بنجاح !",
        "更新头像失败！": "فشل تحديث الصورة الرمزية !",
        "请勿重复设置！": "لا تكرر الإعدادات !",
        "设置姓名成功！": "تعيين الاسم بنجاح !",
        "设置姓名失败！": "فشل تعيين الاسم !",
        "设置性别成功！": "إعداد النجاح بين الجنسين !",
        "设置性别失败！": "فشل في تحديد الجنس !",
        "请设置姓名后再绑定银行卡！": "يرجى تعيين اسمك قبل ربط بطاقتك المصرفية !",
        "请设置提现密码后再绑定银行卡！": "يرجى تعيين كلمة مرور السحب قبل ربط البطاقة المصرفية !",
        "确认绑卡": "تأكيد بطاقة الربط",
        "请输入银行卡号！": "يرجى إدخال البنك !",
        "请输入银行名称！": "الرجاء إدخال اسم البنك !",
        "设置银行卡成功！": "إعداد البطاقة المصرفية بنجاح !",
        "设置银行卡失败！": "فشل إعداد البطاقة المصرفية !",
        "旧密码错误": "خطأ كلمة المرور القديمة",
        "修改密码成功！": "تم تغيير كلمة المرور بنجاح !",
        "修改密码失败！": "فشل في تغيير كلمة المرور !",
        "请填写正确的金额": "يرجى ملء المبلغ الصحيح",
        "提现金额不能少于：": "يجب ألا يقل مبلغ السحب عن :",
        "提现金额不能大于：": "لا يمكن أن يكون مبلغ السحب أكبر من :",
        "当日提现次数已用完": "تم استخدام السحب في ذلك اليوم",
        "提现成功！": "سحب النجاح !",
        "提现失败！": "فشل السحب !",
        "金额": "المبلغ",
        "待审核": "في انتظار المراجعة",
        "审核成功": "المراجعة الناجحة",
        "审核退回": "مراجعة الحسابات",
        "请充值后观看视频！": "يرجى مشاهدة الفيديو بعد إعادة الشحن !",
        "释放即可刷新": "احرر...",
        "加载中": "تحميل...",
        "下拉即可刷新": "اسحب لتحديث...",
    },
    setLang:{
        title: "تعيين اللغة"
    },
    lang:{
        zh: "الصينية",
        jp: "اليابان",
        ara: "اللغات",
        en: "الإنكليزية",
        spa: "الإسبانية",
        it: "الإيطالية",
        de: "الألمانية",
        pt: "البرتغالية",
        fra: "الفرنسية",
        nl: "الهولندية",
        id: "الإندونيسية",
        hi: "هندية",
        kor: "الكورية",
        tr: "التركية",
        swe: "السويدية",
        ga: "الأيرلندية",
        ru: "الروسية",
        vie: "الفيتنامية",
        th: "التايلندية",
        est: "الإستونية",
        be: "البيلاروسية",
        bul: "البلغارية",
        pl: "البولندية",
        ms: "الملايو",
    }
}
export default Ara