const Pt = {
    text: {
        choose: "correspondência",
        bond: "Verificou e pagou um depósito de $10000",
        img: "Representação de retrato",
        intention: "Tolerância dos pés",
        casual: "Instruções para sexo casual",
        txt1: "Nota: Esta plataforma fornece os produtos mais autênticos do gancho e do laço do frete aéreo. Para proteger a privacidade do usuário, a associação requer contato com o atendimento ao cliente ou recomendado por indivíduos bem sucedidos.",
        txt2: "Consultoria de atendimento ao cliente",
        txt3: "guia",
        txt4: "Anote o número da sua irmã e entre em contato com o atendimento ao cliente",
        ok: "OK",
        cancel: "cancelar",
        txt5: "Para o benefício de todos os membros, apenas 1% dos recursos são exibidos no site e as atualizações são irregulares. Para obter recursos detalhados, entre em contato com o atendimento ao cliente.",
        txt6: "Todas as informações dos membros na plataforma são genuínas, seguras e confiáveis. Pode ser usado em todo o país, e novos encontros também podem ser encontrados na mesma rua. Escolha uma mulher que você gosta e conheça-a pessoalmente. Junte-se agora ~",
        txt7: "Informação do Membro",
        txt8: "correspondência",
        txt9: "Utilizar o protocolo",
        txt10: "Eu quero ir a um encontro com ela",
        txt11: "Esta plataforma fornece a busca mais autêntica de deflação. Para proteger a privacidade das informações pessoais, o registro só pode ser feito entrando em contato com o suporte ou aceitando recomendações de nomes reais de indivíduos que tenham procurado com sucesso a experiência de deflação.",
        txt12: "Actualmente 0 emparelhamento online",
        txt13: "44 activação",
        txt14: "Prestações dos membros",
        txt15: "evento",
        txt16: "Vídeo do Membro",
        txt17: "Mente-lhe.",
        txt18: "Informação do Membro",
        txt19: "Novo ID",
        txt20: "encontrar",
        txt21: "Termina a votação",
        txt22: "Resultados durante o período",
        txt23: "período",
        txt24: "Seleccione aleatoriamente o número",
        credit_score: "Pontuação de crédito",
        mail: "Carta da Estação",
        txt25: "A modernização requer 15 pontos percentuais",
        txt26: "Pontos pessoais",
        txt27: "Ponto (8361;)",
        txt28: "Recarga de pontos",
        txt29: "Alterar a localização",
        txt30: "serviço em linha",
        txt31: "breve introdução",
        txt32: "Clique na informação detalhada",
        txt33: "Currículos de troca",
        txt34: "registo de dados",
        txt35: "Mudar a senha",
        txt36: "Alterar a localização",
    },
    login: {
        title: "autenticação", //登录
        username:"Digite seu nome de usuário",//请输入用户名
        password:"Por favor insira o código",//请输入密码
        forgetPaw:"Esqueci minha senha",//忘记密码
        registerNow:"Não tem uma conta? Inscreva-se agora",//没有账户？马上注册
        doLogin:"autenticação",//登录

    },
    register:{
        title: "De registo", //注册
        username:"Digite seu nome de usuário (6-12 letras ou números em inglês)",//请输入用户名(6-12个英文字母或数字)
        password:"Digite a senha de login (6-12 letras ou números em inglês)",//请输入登录密码(6-12个英文字母或数字)
        code:"Por favor insira o código de convite",//请输入邀请码
        agreement:"Tomei conhecimento e concordo com os tratados de abertura de conta",//我已经知晓并同意"开户协议"各项条约
        chooseAgreement:"Por favor, marque abaixo o acordo de abertura de conta!",//请勾选下方开户协议！
        doRegister:"De registo",//注册
    },
    footer:{
        home :"Ficha técnica",//首页
        game:"marcação",//预约
        video:"vídeo",//视频
        mine:"O meu",//我的
    },
    game:{
        title:"Salão de reservas",//预约大厅
        all:"todos",//全部
    },
    choose:{
        title:"concubinas",//选妃
        city:"Cidade grande quan",//城市大全
        rule:"Esta plataforma é a mais autêntica periferia de toda a rede + acompanhante de negócios + paixão da mesma cidade. Para garantir a privacidade pessoal de cada usuário, os clientes só podem participar entrando em contato com a recepcionista ou membro sênior da plataforma recomendado pelo nome real.",//本平台全网最真实外围+商务伴游+同城激情，为保证每个用户的个人隐私，客户仅限通过联系接待员或平台资深会员实名推荐才可加入。
        price:"Processo de preços",//价格流程
        resource:"Quais os recursos disponíveis?",//有哪些资源?
        character:"Influenciadores, modelos, hospedeiras, jovens modelos, estudantes universitários, só você não pode pensar, não pode fazer sem esta plataforma",//网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到
        scope:"Área de serviço?",//服务范围?
        location:"A mesma cidade sobre o canhão livre, em qualquer lugar em todo o país, uma segunda linha de cidade doméstica estão disponíveis localmente, a cidade de terceira linha também pode ser agendada entrando em contato com o recepcionista para agendamento.",//同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排。
    },
    home:{
        recommend:"Sugerir uma tarefa",//推荐任务
        more:"Ver mais ver",//查看更多
        popularity:"Classificação em popularidade",//人气排行
        hot:"Quente recomendado por",//热门推荐
    },
    lottery:{
        jieguo: "Resultado da votação",
        jiesu: "Fim",
        record:"Registro de tarefas",//任务记录
        rule:"Regras de jogo",//玩法规则
        prompt:"Dicas para jogar",//玩法提示
        gameplay:"Escolha os números dentro das formas opcionais e de valor para apostar",//从可选和值形态里面选择号码进行下注
        explain:"Instruções para ganhar",//中奖说明
        wprole:"A soma dos três números de abertura de 11 a 18 é grande; O valor total de 3~ 10 é pequeno;",//三个开奖号码总和值11~18 为大;总和值3~ 10为小;
        example:"Exemplos de apostas",//投注范例
        option:"Esquema de apostas: número de abertura pequeno: 123, ou seja, pequeno e médio",//投注方案：小 开奖号码：123,即中小
        task:"Lista de tarefas",//任务单
        money:"Saldo disponível disponível",//可用余额
        submit:"apresentação",//提交
        selection:"Número de seleção atual",//当前选号
        perNote:"Montante por aposta",//每注金额
        inputMoney:"Por favor insira um valor",//请输入金额
        total:"No total",//总共
        note:"No total",//总共
        statistics:"total",//合计
        empty:"Esvaziar uma ordem",//清空订单
        verify:"Confirmar a submissão",//确认提交
        issue:"Número de emissão",//期号
        num:"Números de abertura",//开奖号码
        big:"grande",//大
        small:"pequeno",//小
        single:"único",//单
        double:"dupla",//双
        unselected:"Não selecionado",//未选择
        drawLottery:"Abertura bem sucedida, número do período:",//开奖成功，期号：
        kefu:"Entre em contato com o administrador para reivindicar a tarefa!",//请联系管理员领取该任务!
    },
    mine:{
        recharge:"Para recarregar",//充值
        withdraw:"Vários locais",//提现
        myMoney:"A minha carteira",//我的钱包
        detail:"pormenores",//详情
        balance:"equilíbrio",//余额
        finance:"Declarações para indivíduos",//个人报表
        order:"Detalhes da conta",//账户明细
        record:"Registro de tarefas",//任务记录
        my:"Um centro pessoal",//个人中心
        notice:"Boletim de informação",//信息公告
        service:"Atendimento ao cliente online",//在线客服
        score: "Pontos",
    },
    setting:{
        title:"As configurações",//设置
        basic:"Configurações de informação básica",//基本信息设置
        password:"Login e senha",//登录密码
        capital:"Senha para fundos",//资金密码
        lang:"línguas",//语言
        quit:"Sair do login",//退出登录
    },
    setPayPassword: {
        title:"Definir uma senha de fundos",//设置资金密码
        opw:"Por favor, insira o código de fundos",//请输入资金密码
        tpw:"Por favor, insira a senha de fundos novamente",//请再次输入资金密码
    },
    gameRecord:{
        reject: "rejeitar",
        profit: "lucro",
        place: "fazer uma encomenda",
        title:"Registro de tarefas",//任务记录
        amountBet:"Montante da aposta",//下注金额
        create:"Hora da ordem",//下单时间
        update:"Tempo de liquidação",//结算时间
    },
    video:{
        title:"Cinema com vídeo",//视频影院
        play:"play",//播放
    },
    playVideo:{
        play: "Jogar uma vez",//次播放
    },
    setname:{
        title:"Alterar nome real",//修改真实姓名
        name:"O nome",//姓名
        truename:"Por favor, digite seu nome real",//请输入真实姓名
        hint:"Para a segurança da sua conta, o nome real precisa coincidir com o nome do cartão bancário obrigatório",//为了您账户安全,真实姓名需要与绑定银行卡姓名一致
    },
    setsex:{
        title:"Modificação de gênero",//性别修改
    },
    bindCard:{
        title:"Preencha o cartão de recebimento",//填写收款卡
        msg:"Por favor, insira as informações do seu cartão de recebimento",//请输入您的收款卡信息
        hint:"Caro usuário, para proteger seus fundos, por favor, vincule seu nome real e defina uma senha de retirada. Se o nome é diferente do nome de abertura da conta, a retirada não será possível.",//尊敬的用户,为了保障您的资金安全,请您绑定您的真实姓名和设置取款密码,如果姓名与开户名不一致,将无法取款
        card: "Número da conta (normal)",
        truecard: "Digite o número da conta (normal)",
        name: "Nome da conta (pseudônimo)",
        truename: "Informe o nome da conta (pseudônimo)",
        financial_institution: "Nome da instituição financeira",
        truefinancial_institution: "Digite o nome da instituição financeira",
        branch: "Nome da filial",
        truebranch: "Informando Nomes de Agências",
        branch_number: "Número da agência",
        truebranch_number: "Informando Números de Agências"
    },
    setbank:{
        title:"Informações do cartão de recebimento",//收款卡信息
        bank:"Adicionar cartão bancário",//添加银行卡
        hint:"Dicas: por favor, ligue um grande banco comercial, se você quiser alterar, entre em contato com o atendimento ao cliente on-line",//提示:请绑定大型商业银行,如需修改,请您联系在线客服
    },
    setLoginPassword:{
        title:"Alterar senha de login",//修改登录密码
        old_password:"A senha antiga",//旧密码
        old_password_p:"Digite sua senha antiga",//请输入旧密码
        o_new_password:"Nova palavra-passe",//新密码
        o_new_password_p:"Por favor, insira uma nova senha",//请输入新密码
        t_new_password_p:"Por favor, digite a nova senha novamente",//请再次输入新密码
    },
    infomation:{
        title:"A informação básica",//基本信息
        head:"cabeça",//头像
        select:"Escolha um avatar",//选择头像
        name:"Nome verdadeiro nome",//真实姓名
        sex:"sexo",//性别
        man:"Os homens",//男
        girl:"As mulheres",//女
        unknown:"desconhecido",//未知
        binding:"A informação obrigatória",//绑定信息
    },
    withdraw: {
        title:"Centro de retirada",//提现中心
        record:"Registro de retirada",//提现记录
        money:"Montante de retirada",//提现金额
        all:"todos",//全部
        min:"Limite único: mínimo",//单笔限额：最低
        max:"O supremo",//最高
        num:"Tempos de retirada: máximo de um dia",//提现次数：一天最高提现
        hint:"Tempo de chegada: o tempo de chegada normal é de cerca de 5 minutos, o mais rápido é de 2 minutos",//到账时间：一般到账时间在5分钟左右，最快2分钟内到账
        quota:"Descrição dos limites",//限额说明
        balance:"equilíbrio",//余额
        withdraw:"Retirar agora mesmo",//马上提现
    },
    withdrawRecord:{
        desc:"Significa que",//说明
        create:"Tempo de submissão",//提交时间
        update:"审核时间",//审核时间
    },
    personalreport:{
        title:"Declarações para indivíduos",//个人报表
        profit:"Quantidade de lucro",//盈利金额
        formula:"Fórmula de cálculo de lucro: quantidade de ganho- quantidade de tarefa",//盈利计算公式 : 中奖金额 - 任务金额
        task:"Montante da missão",//任务金额
        recharge:"Montante da recarga",//充值金额
        withdrawal:"Montante de retirada",//提现金额
        win_money:"Montante dos prêmios",//中奖金额
    },
    service: {
        title:"Atendimento ao cliente online",//在线客服
        relation:"ligação",//联系
        hint:"Dia 7 * 24 horas dedicado ao seu serviço",//全天7 * 24小时竭诚为您服务
    },
    msg:{
        "次":"vez",
        "用户不存在！":"O usuário não existe!",//用户不存在！
        "密码错误！":"Senha incorreta!",
        "登录成功！":"Login efetuado com sucesso!",
        "邀请码不存在！":"O código de convite não existe!",
        "用户名位数错误！":"Número de nome de usuário incorreto!",
        "密码位数错误！":"Dígitos de senha incorretos!",
        "用户名不能存在中文！":"O nome de usuário não pode existir em chinês!",
        "邀请码不能为空！":"O código de convite não pode estar vazio!",
        "注册成功！":"Cadastro realizado com sucesso!",
        "注册失败！":"Registro falhou!",
        "用户已存在":"O usuário já existe",
        "刷新成功！":"Atualização bem sucedida!",
        "请联系顾问或接待员":"Contacte um consultor ou recepcionista",
        "金额错误！":"Valor incorreto!",
        "请选号！":"Por favor, escolha!",
        "请填写金额!":"Por favor, preencha o valor!",
        "余额不足，请联系客服充值！":"Saldo insuficiente, entre em contato com o serviço ao cliente para recarregar!",
        "没有更多了":"Não há mais",
        "没有数据":"Dados não disponíveis",
        "余额不足！":"O saldo é insuficiente!",
        "扣款失败！":"A dedução falhou!",
        "下注异常！":"Uma aposta excepcional!",
        "投注成功！":"Aposta bem sucedida!",
        "参数异常！":"Parâmetros anormais!",
        "获取中":"A adquirir...",
        "请完成任务单后进入":"Por favor, complete a folha de tarefas para entrar",
        "请联系客服充值":"Contacte o serviço ao cliente para recarregar",
        "请设置收款卡!":"Por favor, configure o seu cartão de recolha!",
        "功能已禁用!":"A funcionalidade foi desativada!",
        "账号下线":"Conta desligada",
        "登录/注册":"Entrar/registar",
        "登录可享受更多服务！":"Entre para mais serviços!",
        "未设置":"Não definido",
        "已设置":"Tem sido definido",
        "提交":"apresentação",
        "确定":"A certeza",
        "请填写完整":"Por favor preencha completamente",
        "两次密码不一致！":"Duas senhas diferentes!",
        "设置提现密码成功！":"Senha de retirada definida com sucesso!",
        "提现密码已设置，需要修改请联系客服":"A senha de retirada foi definida, precisa modificá-la, entre em contato com o atendimento ao cliente",
        "已绑定":"Já está ligado",
        "无":"sem",
        "更换头像成功！":"Mudança de foto de perfil com sucesso!",
        "更新头像失败！":"Falha na atualização do avatar!",
        "请勿重复设置！":"Não repita as configurações!",
        "设置姓名成功！":"Nome definido com sucesso!",
        "设置姓名失败！":"Falha ao definir o nome!",
        "设置性别成功！":"Definir o sexo com sucesso!",
        "设置性别失败！":"Definir o gênero falhou!",
        "请设置姓名后再绑定银行卡！":"Por favor, defina o nome antes de ligar o cartão!",
        "请设置提现密码后再绑定银行卡！":"Por favor, defina o código de retirada antes de vincular o cartão!",
        "确认绑卡":"Confirme o cartão amarrado",
        "请输入银行卡号！":"Por favor, insira o número do cartão bancário!",
        "请输入银行名称！":"Digite o nome do banco!",
        "设置银行卡成功！":"Configuração do cartão com sucesso!",
        "设置银行卡失败！":"Falha na configuração do cartão!",
        "旧密码错误":"Senha antiga incorreta",
        "修改密码成功！":"Alterou a senha com sucesso!",
        "修改密码失败！":"Falha ao alterar a senha!",
        "请填写正确的金额":"Por favor, preencha o valor correto",
        "提现金额不能少于：":"O montante de retirada não pode ser inferior a:",
        "提现金额不能大于：":"O montante de retirada não pode ser maior que:",
        "当日提现次数已用完":"O número de retiradas do dia foi esgotado",
        "提现成功！":"Retirada bem sucedida!",
        "提现失败！":"O levantamento falhou!",
        "金额":"montante",
        "待审核":"Para revisão",
        "审核成功":"Auditado com sucesso",
        "审核退回":"Rever e devolver",
        "请充值后观看视频！":"Por favor, recarregue e assista ao vídeo!",
        "释放即可刷新":"Solte e refresque...",
        "加载中":"A carregar...",
        "下拉即可刷新": "O drop-down pode ser atualizado...",
    },
    setLang:{
        title:"Definir o idioma"
    },
    profile: {
        portrait: "Fotografia",
        video: "Vídeo",
        jianjie:" Perfil pessoal",
        liji: "Faça uma consulta agora",
        chongdan: "Faixa de carregamento",
        fd: "Mulher",
        yuyue: "Reserva",
        archives: "Arquivo de irmã",
        age: "Idade",
        height: "Altura",
        weight: "Peso",
        cup: "Copo de cobertura",
        city: "Cidade",
        area: "Área de serviço",
        item: "Item de serviço"
    },
    lang:{
        "zh": "Chinês",
        "jp": "Japão",
        "ara": "Linguagem",
        "en": "Inglês",
        "spa": "Espanhol",
        "it": "Italiano",
        "de": "Alemão",
        "pt": "Português",
        "fra": "Francês",
        "nl": "Holandês",
        "id": "Indonésio",
        "hi": "Hindi",
        "kor": "Coreano",
        "tr": "Turco",
        "swe": "Sueco",
        "ga": "Irish",
        "ru": "Russo",
        "vie": "Vietnamita",
        th: "Tailandês",
        est: "Estoniano",
        be: "Belarus",
        bul: "Búlgaro",
        pl: "Polonês",
        ms: "Malaio",
    }
}
export default  Pt