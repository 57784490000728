const Be = {
    text: {
        choose: "адпаведнасць",
        bond: "Праверана, унесены дэпазіт у 10 000 долараў",
        img: "Адлюстраванне выявы",
        intention: "Выпрабуйце задавальненне",
        casual: "інструкцыі па звычайным сэксе",
        txt1: "Заўвага: гэтая платформа прапануе самыя сапраўдныя падключэнні ў паветры. Каб абараніць прыватнасць карыстальнікаў, членства патрабуе звароту ў службу падтрымкі або рэкамендацыі паспяховага чалавека.",
        txt2: "Пракансультуйцеся са службай падтрымкі кліентаў",
        txt3: "інфармацыя",
        txt4: "Калі ласка, запішыце нумар вашай сястры і звярніцеся ў службу падтрымкі",
        ok: "добра",
        cancel: "адмяніць",
        txt5: "Для нашых удзельнікаў толькі 1% ​​рэсурсаў адлюстроўваецца на сайце, а абнаўленні адбываюцца нерэгулярна. Каб атрымаць дадатковыя рэсурсы, звярніцеся ў службу падтрымкі.",
        txt6: "Уся інфармацыя аб удзельніках на платформе сапраўдная, бяспечная і надзейная. Ён даступны па ўсёй краіне, і вы нават можаце пазнаёміцца ​​з новымі людзьмі ў адным горадзе. Выберыце каханую жанчыну і пазнаёміцеся з ёй асабіста. Далучайцеся зараз!",
        txt7: "Інфармацыя аб удзельніках",
        txt8: "адпаведнасць",
        txt9: "ўмовы абслугоўвання",
        txt10: "я хачу сустракацца з ёй",
        txt11: "Наша платформа забяспечвае найбольш рэалістычны спосаб знайсці сэкс-сяброў. Каб абараніць канфідэнцыяльнасць вашай асабістай інфармацыі, зарэгістравацца могуць толькі тыя, хто звяртаўся ў службу падтрымкі або атрымаў рэкамендацыю сапраўднага імя ад чалавека, які ўжо паспяхова знайшоў сэкс-сябра.",
        txt12: "У цяперашні час 0 спалучэнняў онлайн",
        txt13: "44 актываваны",
        txt14: "правы членства",
        txt15: "падзея",
        txt16: "удзельнік відэа",
        txt17: "ездзіць на ім і падманваць",
        txt18: "Інфармацыя аб удзельніках",
        txt19: "Новае пасведчанне асобы",
        txt20: "сустрэча",
        txt21: "Галасаванне закрыта",
        txt22: "вынікі перыяду",
        txt23: "перыяд",
        txt24: "нумар латарэі",
        credit_score: "крэдытны бал",
        mail: "станцыйны ліст",
        txt25: "Абнаўленне патрабуе 15 балаў",
        txt26: "асабістыя балы",
        txt27: "Ачкі (₩)",
        txt28: "Зарадныя балы",
        txt29: "Выкупіць балы",
        txt30: "анлайн-сэрвісы",
        txt31: "профіль",
        txt32: "Кропкавыя дэталі",
        txt33: "Гісторыя абмену",
        txt34: "запіс дадзеных",
        txt35: "пароль абмену",
        txt36: "Выкупіць балы",
    },
    login: {
        title: "■ Уваход",
        username: "Калі ласка, увядзіце імя карыстальніка",
        password: "Калі ласка, увядзіце пароль",
        forgetPaw: "забываюць пароль",
        registerNow: "Няма рахункаў? рэгістрацыя неадкладна",
        doLogin: "■ Уваход",

    },
    register:{
        title: "网天公司",
        username: "Калі ласка, увядзіце імя карыстальніка (6-12 літар або лічбы)",
        password: "Калі ласка, увядзіце пароль (6-12 літар або нумар)",
        code: "Калі ласка, увядзіце код запрашэння",
        agreement: "Я ўжо ведаю і згодны з дамовай « пагадненне аб адкрыцці рахунку »",
        chooseAgreement: "Калі ласка, праверце ніжэй пагаднення рахункі!",
        doRegister: "网天公司",

    },
    footer:{
        home : "Галоўная",
        game: "прызначэнне",
        video: "网天公司",
        mine: "My",

    },
    game:{
        title: "прызначэнне зала",
        all: "усе",

    },
    choose:{
        title: "■ выбары прынцэса",
        city: "горад Daquan",
        rule: "Гэтая платформа з'яўляецца найбольш рэалістычнай ўсёй сеткай + + бізнес суправаджэнне запал горада, для таго, каб забяспечыць недатыкальнасць асабістага жыцця кожнага карыстальніка, кліент можа далучыцца толькі шляхам кантакту з прыёмам або платформай, рэкамендаваным старэйшым членам рэальнага імя.",
        price: "працэс коштаў",
        resource: "Якія рэсурсы?",
        character: "Чыстая чырвоная, мадэль, сцюардэсы, мяккі рэжым, студэнты, толькі вы не можаце думаць, не менш, чым гэты ўзровень",
        scope: "Паслугі?",
        location: "Горад бясплатна аб пісталеце, у любым месцы ў паветры, у краіне другога ўзроўню гарадоў мясцовыя, трэці эшалон горада могуць таксама прызначыць сустрэчу, звяжыцеся з механізмам прыёму.",

    },
    home:{
        recommend: "Рэкамендуемая задача",
        more: "Больш падрабязна",
        popularity: "Папулярныя Рэйтынг",
        hot: "Папулярныя рэкамендацыі",

    },
    lottery:{
        jieguo: "■ Вынікі галасавання",
        jiesu: "канец",
        record: "Задача запіс",
        rule: "правілы гульні",
        prompt: "■ Саветы гульні",
        gameplay: "Выберыце колькасць ставак ад дадатковай формы і значэнняў ўнутры",
        explain: "Перамога Апісанне",
        wprole: "Сума кошту трох латарэйных нумароў ад 11 да 18 вялікая; Сума кошту ад 3 да 10 малых;",
        example: "■ стаўкі прыклад",
        option: "Стаўкі на праграму: невялікія нумары латарэі: 123, гэта значыць, малыя і сярэднія",
        task: "адна задача",
        money: "даступны баланс",
        submit: "прадстаўленне",
        selection: "У цяперашні час Піка",
        perNote: "сума кожнай ін'екцыі",
        inputMoney: "Калі ласка, увядзіце суму",
        total: "■ У агульнай складанасці",
        note: "■ У агульнай складанасці",
        statistics: "赛马里尼",
        empty: "Вычышчанае заказы",
        verify: "пацверджанне падачы",
        issue: "№",
        num: "нумары латарэі",
        big: "GAME",
        small: "маленькі",
        single: "■ сінгл",
        double: "двайны",
        unselected: "Не абраны",
        drawLottery: "Латарэя поспех, перыяд:",
        kefu: "Калі ласка, звяжыцеся з адміністратарам задачы!",

    },
    mine:{
        recharge: "перазарадка",
        withdraw: "зняцце",
        myMoney: "Мой кашалёк",
        detail: "дэталі",
        balance: "2.18",
        finance: "Асабістыя справаздачы",
        order: "дэталі рахункі",
        record: "Задача запіс",
        my: "асабісты цэнтр",
        notice: "■ Інфармацыя Аб'ява",
        service: "Онлайн абслугоўванне кліентаў",
        score: "інтэграл",
    },
    setting:{
        title: "网天公司",
        basic: "Асноўныя налады інфармацыі",
        password: "■ пароль",
        capital: "■ сродкі пароль",
        lang: "мова",
        quit: "■ Выхад Увайсці",

    },
    setPayPassword: {
        title: "Ўсталяваць пароль капіталу",
        opw: "Калі ласка, увядзіце пароль капіталу",
        tpw: "Калі ласка, увядзіце пароль капіталу зноў",

    },
    gameRecord:{
        reject: "Отклонено",
        profit: "Прибыль",
        place: "Выписать заказ",
        title: "Задача запіс",
        amountBet: "стаўка сума",
        create: "заказы час",
        update: "час разлікаў",

    },
    video:{
        title: "відэа тэатр",
        play: "гулец",

    },
    playVideo:{
        play: "раз Гуляць",

    },
    setname:{
        title: "Змяніць сапраўднае імя",
        name: "网天公司",
        truename: "Калі ласка, увядзіце сапраўднае імя",
        hint: "Для вашага рахунку бяспекі, сапраўднае імя павінна адпавядаць імя банкаўскай карты звязвання",

    },
    setsex:{
        title: "гендэрныя змены",

    },
    bindCard:{
        title: "Запоўніце дэбіторскую карту",
        msg: "Калі ласка, увядзіце інфармацыю аб аплатнай карты",
        hint: "Паважаныя карыстальнікі, у мэтах абароны фінансавай бяспекі, калі ласка, звяжыце сваё сапраўднае імя і ўсталяваць пароль вываду, калі імя не адпавядае з імем ўліковага запісу, не будзе здымаць грошы",
        card: "Нумар рахунку (звычайны)",
        truecard: "Увядзіце рахунак (звычайны)",
        name: "Імя ўліковага запісу (сапраўднае імя)",
        truename: "Увядзіце імя ўліковага запісу (сапраўднае імя)",
        financial_institution: "Назва фінансавых устаноў",
        truefinancial_institution: "Увядзіце імя фінансавых устаноў",
        branch: "■ Аддзяленне",
        truebranch: "увядзіце назву філіяла",
        branch_number: "нумар філіяла",
        truebranch_number: "Увядзіце нумар філіяла"
    },
    setbank:{
        title: "інфармацыя дэбіторскай запазычанасці карты",
        bank: "Дадаць банкаўскую карту",
        hint: "Рада: Калі ласка, звяжыце буйныя камерцыйныя банкі, каб змяніць, калі ласка, звяжыцеся з онлайн-абслугоўвання кліентаў",

    },
    setLoginPassword:{
        title: "Змена пароля ўваходу ў сістэму",
        old_password: "стары пароль",
        old_password_p: "Калі ласка, увядзіце стары пароль",
        o_new_password: "новы пароль",
        o_new_password_p: "Калі ласка, увядзіце новы пароль",
        t_new_password_p: "Калі ласка, увядзіце новы пароль зноў",

    },
    infomation:{
        title: "Асноўная інфармацыя",
        head: "галава",
        select: "Выберыце галаву",
        name: "сапраўднае імя",
        sex: "Bright Horizons",
        man: "мужчыны",
        girl: "■ жанчына",
        unknown: "невядомая",
        binding: "інфармацыя звязвання",

    },
    withdraw: {
        title: "Цэнтр зняцця",
        record: "Адмена запіс",
        money: "сума зняцця",
        all: "усе",
        min: "Адзінкавыя абмежаванні: Мінімальны",
        max: "",
        num: "Выхад: адзін дзень зняцце",
        hint: "Час прыбыцця: агульны час прыбыцця каля 5 хвілін, самы хуткі 2 хвіліны прыбыцця",
        quota: "■ апісанне квот",
        balance: "2.18",
        withdraw: "Адразу ж зняцце",

    },
    withdrawRecord:{
        desc: "Апісанне",
        create: "час прадстаўлення",
        update: "час аўдыту",

    },
    personalreport:{
        title: "Асабістыя справаздачы",
        profit: "сума прыбытку",
        formula: "Формула прыбытку: прызавы фонд-сума задачы",
        task: "сума місіі",
        recharge: "Прадаплочаныя сума",
        withdrawal: "сума зняцця",
        win_money: "прызавы фонд",

    },
    service: {
        title: "Онлайн абслугоўванне кліентаў",
        relation: "■ Кантакт",
        hint: "7 * 24 гадзіны ў суткі, каб служыць вам самаадданым",

    },
    profile: {
        portrait: "Фотаграфія",
        video: "відэа",
        jianjie: "Профіль",
        liji: "прызначэнне неадкладна",
        chongdan: "Дыяпазон зарадкі",
        fd: "лэдзі",
        yuyue: "прызначэнне",
        archives: "сястра файлы",
        age: "330.06",
        height: "вышыня",
        weight: "вага",
        cup: "кубак",
        city: "горад",
        area: "вобласць абслугоўвання",
        item: "Паслугі"
    },
    msg:{
        "次": "раз",
        "用户不存在！": "Карыстальнікі не існуюць!",
        "密码错误！": "Пароль памылка!",
        "登录成功！": "Увайсці паспяхова!",
        "邀请码不存在！": "код запрашэння не існуе!",
        "用户名位数错误！": "Імя карыстальніка лічбы няправільна!",
        "密码位数错误！": "Пароль лічбы няправільна!",
        "用户名不能存在中文！": "Імя карыстальніка не можа існаваць па-кітайску!",
        "邀请码不能为空！": "код запрашэння не пусты!",
        "注册成功！": "Рэгістрацыя паспяхова!",
        "注册失败！": "Рэгістрацыя не атрымалася!",
        "用户已存在": "Карыстальнікі ўжо існуюць",
        "刷新成功！": "Абнавіць поспех!",
        "请联系顾问或接待员": "Калі ласка, звяжыцеся з кансультантам або прыёмам",
        "金额错误！": "Колькасць памылак!",
        "请选号！": "Калі ласка, выпадковы выбар!",
        "请填写金额!": "Калі ласка, запоўніце суму!",
        "余额不足，请联系客服充值！": "Адсутнасць балансу, калі ласка, звяжыцеся з кліентам перазарадзіць!",
        "没有更多了": "Няма больш",
        "没有数据": "Няма дадзеных",
        "余额不足！": "Адсутнасць балансу!",
        "扣款失败！": "Дэбет не ўдалося!",
        "下注异常！": "Стаўкі на выключэнне!",
        "投注成功！": "Стаўкі на поспех!",
        "参数异常！": "Анамальныя параметры!",
        "获取中": "Атрымаць у...",
        "请完成任务单后进入": "Пасля завяршэння адной задачы",
        "请联系客服充值": "Калі ласка, звяжыцеся з перазарадзіць абслугоўвання кліентаў",
        "请设置收款卡!": "Калі ласка, ўсталюйце дэбіторскую карту!",
        "功能已禁用!": "Функцыя была адключаная!",
        "账号下线": "Рахункі з канвеера",
        "登录/注册": "Увайсці/рэгістрацыя",
        "登录可享受更多服务！": "Увайсці могуць карыстацца больш паслуг!",
        "未设置": "не ўстаноўлены",
        "已设置": "былі ўсталяваныя",
        "提交": "прадстаўленне",
        "确定": "OK",
        "请填写完整": "Калі ласка, запоўніце",
        "两次密码不一致！": "Два неадпаведнасці пароля!",
        "设置提现密码成功！": "Ўсталяваць пароль для поспеху!",
        "提现密码已设置，需要修改请联系客服": "Пароль зняцце было ўстаноўлена, неабходна змяніць, калі ласка, звяжыцеся з абслугоўваннем кліентаў",
        "已绑定": "Ён быў звязаны",
        "无": "Non-",
        "更换头像成功！": "T замяніць галаву паспяхова!",
        "更新头像失败！": "Абнаўленне аватар не ўдалося!",
        "请勿重复设置！": "Не паўтараць налады!",
        "设置姓名成功！": "Налады імя паспяхова!",
        "设置姓名失败！": "Налады імя не ўдалося!",
        "设置性别成功！": "Ўсталяваць гендэрны поспех!",
        "设置性别失败！": "Налады пол не ўдалося!",
        "请设置姓名后再绑定银行卡！": "Калі ласка, ўсталюйце імя, а затым звязаць банкаўскую карту!",
        "请设置提现密码后再绑定银行卡！": "Калі ласка, ўсталюйце пароль, а затым прывязваць банкаўскую карту!",
        "确认绑卡": "Пацверджанне звязвання карты",
        "请输入银行卡号！": "Калі ласка, увядзіце банк!",
        "请输入银行名称！": "Калі ласка, увядзіце імя банка!",
        "设置银行卡成功！": "Ўсталяваць поспех банкаўскай карты!",
        "设置银行卡失败！": "Налады банкаўскай карты не ўдалося!",
        "旧密码错误": "Старыя памылкі пароля",
        "修改密码成功！": "Змена пароля паспяхова!",
        "修改密码失败！": "Змена пароля не ўдалося!",
        "请填写正确的金额": "Калі ласка, запоўніце правільную суму",
        "提现金额不能少于：": "Аднаўленне сумы не менш:",
        "提现金额不能大于：": "Аднаўленне сумы не можа быць больш:",
        "当日提现次数已用完": "Дзень ужо вычарпаў зняцце",
        "提现成功！": "Зняцце поспеху!",
        "提现失败！": "Аднаўленне не ўдалося!",
        "金额": "■ Сума",
        "待审核": "Для таго, каб разгледзець",
        "审核成功": "паспяховае разгляд",
        "审核退回": "агляд вяртаецца",
        "请充值后观看视频！": "Калі ласка, глядзіце відэа пасля перазарадкі!",
        "释放即可刷新": "Выпуск можа быць абноўлены...",
        "下拉即可刷新": "Вы можаце абнавіць ўніз...",
        "加载中": "Загрузка у...",

    },
    setLang:{
        title: "■ мова налады"
    },
    lang:{
        zh: "2010年3月15的价格的价",
        jp: "Японія",
        ara: "моўная",
        en: "англійская",
        spa: "Іспанскі",
        it: "італьянскі",
        de: "Нямецкі",
        pt: "Партугалія",
        fra: "французская",
        nl: "Галандская",
        id: "Інданезія",
        hi: "Індыя-сайт",
        kor: "Карэйская",
        tr: "Турцыя",
        swe: "шведскі",
        ga: "ірландская",
        ru: "Расія",
        vie: "В'етнамская",
        th: "Тайская",
        est: "Эстонія",
        be: "■ Беларусь",
        bul: "Балгарская",
        pl: "польскі",
        ms: "Малайская",

    }
}
export default Be